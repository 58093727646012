<template>
	<div class="d-flex align-items-center bg-auth">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">

					<!-- Image -->
					<div class="text-center">
						<img src="@/assets/img/illustrations/happiness.svg" alt="..." class="img-fluid">
					</div>

				</div>
				<div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
					<template v-if="!isVerified">
						<!-- Heading -->
						<h1 class="display-4 mb-3 text-white text-center">
							Email Confirmation
						</h1>

						<!-- Subheading -->
						<p class="text-muted mb-4">
							Verify your email address within 3 minutes.
						</p>

						<div class="form-group">
							<label for="verifyToken" class="text-white">Enter Code</label>
							<input type="text" class="form-control" v-model="token" id="verifyToken">
						</div>

						<!-- Button -->
						<button-loading
							class="btn btn-lg btn-block btn-primary mb-3 custom-btn"
							type="submit"
							:loading="loading"
							@click.prevent="verify"
						>
							{{ $t('Verify') }}
						</button-loading>
					</template>
					<template v-else>
						<h1 class="display-4 text-white text-center">
							Verification complete!
							<hr/>
							<span class="h2 mr-3">Redirecting to Dashboard</span>
							<div class="spinner-border text-success" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</h1>
					</template>

				</div>
			</div> <!-- / .row -->
		</div>
	</div>
</template>

<script>
import ButtonLoading from '@/components/ButtonLoading'
import {mapState, mapActions, mapGetters} from "vuex";

export default {
	components: {
		ButtonLoading
	},
	data() {
		return {
			token: null,
			isVerified: false
		};
	},
	computed: {
		...mapState("authManagement", {
			loading: "isCreatePending"
		}),
	},
	created() {
		//this.verifyTokenFunc();
		let user = this.$route.params.user
		console.log(user)
		if (!user)
			this.$router.push({name: 'login'})
	},
	methods: {

		...mapActions("authManagement", {
			verifyToken: "create",
		}),

		verify() {
			let user = this.$route.params.user
			this.verifyToken({
				action: "verifySignupShort",
				value: {
					user: {
						email: user.email
					},
					token: this.token
				}
			})
				.then(res => {
					setTimeout( () => {
						let userData = {
							email: user.email,
							password: user.password,
							strategy: 'local'
						}
						this.isVerified = true;
						this.$store.dispatch('auth/authenticate', userData).then( res => {
							this.$router.push({ name: 'links' });
						})
					}, 500)
					//console.log(res)
					//$router.push({ name: 'login'})
				})
				.catch(error => {
					//console.log(error)
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-auth {
	height: 100%;
	min-height: 100vh;
}
</style>
